import { getCurrentCompanyName } from './PackageJsonHelper';

const en_resources = require('../resources/en/Resources.json');
const ch_resources = require('../resources/ch/Resources.json');

//Resources Name
const resources_name = require('../resources/ResourcerName.json');

export function getResource(resourceName){
    let language = sessionStorage.getItem('360Language');
    switch (language) {
        case 'English':
            return en_resources[resourceName];
        case 'Chinese':
            return ch_resources[resourceName];
        default:
            break;
    }
}

export function getCompanyName() {
    const companyName = getCurrentCompanyName();
    switch (companyName) {
        case 'Nexolink':
            return resources_name['Nexolink'];
        case 'Newport':
            return resources_name['NewPort'];
        case 'Premier':
            return resources_name['Premier'];
        case 'Xushi':
            return resources_name['Xushi'];
        default:
            return resources_name['Nexolink'];
    }
}

export function getURLTermsANDCondition(customerType, country) {
    if (customerType === 'Corporate') {
        switch (country) {
            default:
                return 'https://www.afex.com/docs/unitedkingdom/uk_business_account_tcs.pdf';
        }
    } else if (customerType === 'Individual') {
        switch (country) {
            default:
                return 'https://www.afex.com/docs/unitedkingdom/uk_personal_account_tcs.pdf';
        }
    }
}