import axios from 'axios';
import fileDownload from 'js-file-download';
import React from 'react';
import { getCurrentCompanyName, getTermsAndConditions } from '../../helper/PackageJsonHelper';
import { getResource } from '../../helper/ResourcesHelper';
import { sendAgreement } from '../../networking/NetworkingRegForm';
import AlertBox from '../shared/AlertBox';
import AttachmentsDialog from '../shared/AttachmentsDialog';
import { MyDocumentCorporate, savePdf, savePdfBuffer } from '../shared/MyDocumentCorporate';
import { MyDocumentIndividual, saveIndividualPdf, saveIndividualPdfBuffer } from '../shared/MyDocumentIndividual';
import NewDynamicTable from '../shared/NewDynamicTable';
import SignatureBox from '../shared/SignatureBox';

const currentCompanyName = getCurrentCompanyName();

export default class CustomerAgreement extends React.Component {
    state = {
        customerAgreement: false,
        documents: {
            accountFile: '',
            accountFileName: '',
            isComplete: false,
            customerAgreement: false,
        },
        TaskList: [],
        readOnly: false,
        showDeleteAlert: false,
        //
        showAttachmentsDialog: false,
        rowClick: false,
        signatureClick:false,
        signatureResponse:false
    }
    componentDidUpdate(prevProps) {
        if (prevProps.objCustomerAgreement !== this.props.objCustomerAgreement && this.props.objCustomerAgreement != null) {
            this.setState({
                customerAgreement: this.props.objCustomerAgreement.customerAgreement,
                TaskList: this.props.objCustomerAgreement.TaskList,
                signatureResponse: this.props.objCustomerAgreement.signatureResponse,
                documents: { ...this.state.documents, customerAgreement: this.props.objCustomerAgreement.customerAgreement }
            }, () => {
                if (this.state.TaskList.length > 0) {
                    this.state.TaskList.forEach(value => {
                        if (value.Title === 'Original signed agreement') {
                            this.setState({
                                customerAgreement: this.props.objCustomerAgreement.customerAgreement,
                                documents: {
                                    ...this.state.documents,
                                    accountFile: value.Files[0].content,
                                    accountFileName: value.Files[0].filename,
                                    isComplete: true,
                                    customerAgreement: this.props.objCustomerAgreement.customerAgreement
                                }
                            }, () => {
                                this.props.CustomerAgreement(this.state.documents);
                            });
                        }
                    });
                } this.props.CustomerAgreement(this.state.documents);
            });
        }
        if (prevProps.readOnly !== this.props.readOnly && this.props.readOnly != null) {
            this.setState({ readOnly: this.props.readOnly })
        }
    }
    handleChangeUtilityBillFile = async (event) => {
        if (event.target.files.length > 0) {
            const file = event.target.files[0];
            const base64 = await this.readFileAsBase64(file);
            const fileContent = base64.replace(/^data:.+;base64,/, '');
            const newDocuments = {
                ...this.state.documents,
                accountFile: fileContent,
                accountFileName: file.name,
                isComplete: true,
                customerAgreement: this.state.customerAgreement
            };
            this.setState({ documents: newDocuments, readOnly: false, fromUpload: true });
            this.props.CustomerAgreement(newDocuments);
        } else {
            const newDocuments = {
                ...this.state.documents,
                accountFile: '',
                accountFileName: '',
                isComplete: false,
                customerAgreement: this.state.customerAgreement
            };
            this.setState({ documents: newDocuments, readOnly: this.props.readOnly ? true : false });
            this.props.CustomerAgreement(newDocuments);
        }
    }

    readFileAsBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (event) => {
                resolve(event.target.result);
            };
            reader.onerror = (err) => {
                reject(err);
            };
            reader.readAsDataURL(file);
        });
    }

    getPDF = async () => {
        switch (this.props.type) {
            case 'Corporate':
                savePdf(<MyDocumentCorporate obj={this.props.allObjts} />, 'CorporateForm.pdf')
                break;
            case 'Individual':
                saveIndividualPdf(<MyDocumentIndividual obj={this.props.allObjts} />, 'IndividualForm.pdf')
                break;
            default:
                break;
        }
    }

    getPDFBuffer = async () => {
        let TaskList = this.state.TaskList;
        switch (this.props.type) {
            case 'Corporate':
                TaskList.forEach((value, index) => {
                    if (value.Title === 'Original signed agreement') {
                        TaskList.splice(index, 1);
                        this.setState({
                            TaskList,
                            showDeleteAlert: false,
                            documents: { ...this.state.documents, isComplete: false, customerAgreement: this.state.customerAgreement }
                        });
                    }
                });
                
                var file = 'CorporateForm.pdf';
                var url = await savePdfBuffer(<MyDocumentCorporate obj={this.props.allObjts} />);
                var base64 =  await this.readFileAsBase64(url);
                var fileContent = base64.replace(/^data:.+;base64,/, '');
                var newDocuments = {
                    ...this.state.documents,
                    accountFile: fileContent,
                    accountFileName: file,
                    isComplete: true,
                    customerAgreement: this.state.customerAgreement
                };
                this.setState({ documents: newDocuments, readOnly: false, fromUpload: false  });
                this.props.CustomerAgreement(newDocuments);
                break;
            case 'Individual':                
                TaskList.forEach((value, index) => {
                    if (value.Title === 'Original signed agreement') {
                        TaskList.splice(index, 1);
                        this.setState({
                            TaskList,
                            showDeleteAlert: false,
                            documents: { ...this.state.documents, isComplete: false, customerAgreement: this.state.customerAgreement }
                        });
                    }
                });
                
                var file = 'IndividualForm.pdf';
                var url = await saveIndividualPdfBuffer(<MyDocumentIndividual obj={this.props.allObjts} />);
                var base64 =  await this.readFileAsBase64(url);
                var fileContent = base64.replace(/^data:.+;base64,/, '');
                var newDocuments = {
                    ...this.state.documents,
                    accountFile: fileContent,
                    accountFileName: file,
                    isComplete: true,
                    customerAgreement: this.state.customerAgreement
                };
                this.setState({ documents: newDocuments, readOnly: false, fromUpload: false  });
                this.props.CustomerAgreement(newDocuments);
                break;
            default:
                break;
        }
    }
    getTCPDF = (filename) => {
        const url = getTermsAndConditions();
        axios.get(url, {
        responseType: 'blob',
      })
      .then((res) => {
        fileDownload(res.data, filename)
      })
    }
    handleUpdateCheck = (event) => {
        this.setState({
            customerAgreement: event.target.checked,
            documents: { ...this.state.documents, customerAgreement: event.target.checked }
        }, () => { this.props.CustomerAgreement(this.state.documents); });
    }
    SubmitPersonalRegistration = async () => {
        this.props.submit360();
    }
    CancelPersonalRegistration = async () => {
        this.props.navigate('/');
    }
    deleteAttachmentClick(obj) {
        this.setState({ showDeleteAlert: true, readyToDeleteAttachment: obj });
    }

    closeDeleteAlert() {
        this.setState({ showDeleteAlert: false, readyToDeleteAttachment: {} });
    }

    openDeleteAlert() {
        this.setState({ showDeleteAlert: true });
    }

    yesDeleteAlert() {
        let TaskList = this.state.TaskList
        
        TaskList.forEach((value, index) => {
            if (value.Files[0].filename === this.state.readyToDeleteAttachment.filename) {
                TaskList.splice(index, 1);
                this.setState({
                    TaskList,
                    showDeleteAlert: false,
                    signatureResponse: false,
                    documents: { ...this.state.documents, isComplete: false,
                        accountFile: null,
                        accountFileName: ''
                        , customerAgreement: this.state.customerAgreement }
                }, () => {
                    this.props.CustomerAgreement(this.state.documents);
                });
            }
        })
    }
    //RowClickTable
    handleRowClick = () => {
        this.setState({ showAttachmentsDialog: true, rowClick: true })
    }
    showAttachmentsDialog = () => {
        this.setState({ showAttachmentsDialog: false })
    }
    changeState = () => {
        this.setState({ rowClick: false })
    }
    SignatureClick = () => {
        this.setState({ signatureClick: true })
    }
    CloseSignature = () => {
        this.setState({ signatureClick: false })
    }
    AddSignature = (response) => {
        /*{Description:'Include Signature', Value: Yes/No} */
        let model = [{ Description: 'Include Signature', Value: response }]
        this.props.addProps_CustomerAgreement(model);
        this.setState({ signatureResponse: response !== 'Yes' ? false : true })
        this.getPDFBuffer();
    }
    viewTerms = () => {
        /*
            const file = new Blob([ this.props.TermsPDF ], { type: 'pdf' });
            const element = document.createElement('a');
            element.href = URL.createObjectURL(file);
            element.download = 'Terms & Conditions.pdf';
            document.body.appendChild(element);
            element.click();
        */
        var win = window.open(this.props.TermsPDF, '_blank');
        win.focus();
    }

    SendAgreement = () =>{
        if(this.state.documents != null && this.state.documents.accountFileName != '')
        {
            this.setState({ loading: true});
            var fileName = this.state.documents.accountFileName;
            if(fileName.indexOf('.pdf') == -1)
            {
                fileName = fileName+'.pdf';
            }
            let model = {
                CustomerID: parseInt(sessionStorage.getItem('ActualCustomerID')),
                Email: sessionStorage.getItem('ActualCustomerEmail'),
                fileContent: this.state.documents.accountFile,
                Filename: fileName
            }
            sendAgreement(model).then(json => {
                if(json.ErrorMessage == null || json.ErrorMessage == '') {
                        this.setState({ loading: false, showAlert: true, alertTitle: 'Success', alertMessage: getResource('Message_EmailSent')});
                }
                else
                {
                    this.setState({ loading: false, showAlert: true, alertTitle: 'Error', alertMessage: getResource('Message_ErrorMail')});
                       
                }
            });
        }
    }

    closeAlert = () =>
    {
        this.setState({ showAlert: false});
    }

    render() {
        let front = '';
        if (this.state.TaskList.length > 0) {
            this.state.TaskList.forEach(value => {
                if (value.Title === 'Original signed agreement') {
                    front = value.Files;
                }
            });
        }
        return (
            <div className="row" id="customer_agreement">
                <div className="col-sm-12 col-xs-12">
                    <div className="row">
                        <div className="col-md-12">
                            <h5>{getResource('Customer_Paragraph')}</h5>

                            <div className="row">
                                <div className="col-sm-6 col-xs-12">
                                    <button type="button" id="btn-signeture-alert" style={{width:'290px'}} disabled={this.state.signatureResponse} className="btn btn-primary" onClick={this.SignatureClick}>
                                        {getResource('Button_DigitalSignature')}
                                    </button>
                                </div>
                                <div className="col-sm-6 col-xs-12">
                                    {currentCompanyName !== 'Khyber' && <button type="button" id="btn-generate-pdf" style={{width:'290px'}} className="btn btn-primary" onClick={this.getPDF}>
                                        {getResource('Button_Download')}
                                    </button>}
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-6 col-xs-12">
                                    <button type="button" id="btn-sendAgreement" style={{width:'290px'}}  disabled={this.state.documents == null || this.state.documents.accountFileName == ''} className="btn btn-primary" onClick={this.SendAgreement}>
                                        {getResource('Button_SendAgreement')}
                                    </button>
                                </div>
                                <div className="col-sm-6 col-xs-12">
                                    {currentCompanyName !== 'Khyber' && <button type="button" id="btn-generate-tc-pdf" className="btn btn-primary" style={{width:'290px'}}
                                    onClick={() => this.getTCPDF('Terms and conditions.pdf')}>{/*this.viewTerms}>*/}
                                        {getResource('Button_TC_Download')} 
                                    </button>}
                                </div>
                            </div>
                            
                            <div className="row uk-margin">
                                <div className="col-md-12">
                                    <div className="over-hidden">
                                        {
                                            front === '' && this.state.fromUpload &&
                                            <input type="file"
                                                name="document_form" id="document_form"
                                                onChange={this.handleChangeUtilityBillFile}
                                                data-rule-required="true"
                                                data-msg-required="Attachment required"
                                                data-accept-size="512000"
                                                data-accept-size-message="Maximum 500 kb"
                                                data-accept-file-types="jpg|jpeg|png|gif|xls|xlsx|pps|ppt|pptx|doc|docx|pdf"
                                                data-wrong-file-types-message="Only Images, PDF or Office Document is allowed"
                                            />
                                        }
                                        {
                                            front === '' && !this.state.fromUpload && this.state.documents.accountFileName != null &&
                                            <label htmlFor="document_form" className='' onClick={this.getPDF} style={{cursor: 'pointer'}}>
                                                {this.state.documents.accountFileName}
                                            </label>
                                        }
                                        {
                                            front !== '' &&
                                            <NewDynamicTable key="personal_identification1"
                                                hiddenHeaders={['description', 'AttachmentId', 'content']}
                                                data={front}
                                                enableClick={true}
                                                clickFunction={this.handleRowClick}
                                                newSearch={false}
                                                useDeleteButton={true}
                                                deleteFunction={this.deleteAttachmentClick.bind(this)}
                                                enableSortByHeader={false}
                                                usePaginator={false}
                                                useFilter={false}
                                            />
                                        }
                                        {
                                            front !== '' &&
                                            <AttachmentsDialog
                                                open={this.state.showAttachmentsDialog}
                                                onClose={this.showAttachmentsDialog}
                                                attachmentId={front[0].AttachmentId}
                                                file={front[0]}
                                                rowClick={this.state.rowClick}
                                                changeState={this.changeState}
                                            />
                                        }
                                        <label htmlFor="document_form" className='small'>{getResource('Customer_Attachment')}</label>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-12">
                                    {/* <label className="description" htmlFor="terms">
                                        <input id="terms" name="terms"
                                            data-rule-required="true"
                                            data-msg-required="Field Required."
                                            type="checkbox"
                                            checked={this.state.customerAgreement}
                                            onClick={this.handleUpdateCheck} /> {getResource('Checkbox_Read')} *
                                        </label> */}
                                        {(!this.state.customerAgreement || !this.state.signatureResponse) && (<label className="error">
                                            {getResource('Customer_Agreement_Error')}
                                        </label>)}
                                        <label className="description" htmlFor="terms">
                                        <input id="terms" name="terms"
                                            data-rule-required="true"
                                            data-msg-required="Field Required."
                                            type="checkbox"
                                            checked={this.state.customerAgreement}
                                            onClick={this.handleUpdateCheck} />{getResource('Checkbox_Accept')} *
                                        </label>
                                </div>
                            </div>
                            <div id="submit-form-area"><div className="row">
                                <div className="col-md-6">
                                    <button disabled={!this.state.customerAgreement || !this.state.signatureResponse} type="button" id="save-form" className="btn btn-primary" onClick={this.SubmitPersonalRegistration}>{getResource('Final_Submit')}</button>
                                            &nbsp;
                                    <button type="button" id="cancel-form" className="btn btn-primary" onClick={this.CancelPersonalRegistration}>{getResource('Button_Cancel')}</button>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div> {/* .col-md-3 */}
                <AlertBox
                    open={this.state.showAlert}
                    onClose={this.closeAlert}
                    title={this.state.alertTitle}
                    message={this.state.alertMessage}
                    type="Ok"
                    okClick={this.closeAlert}
                />
                <AlertBox open={this.state.showDeleteAlert} onClose={this.closeDeleteAlert.bind(this)} title="Are you sure you want to delete this file?" message="This action can not be undone." type="Yes/No" yesClick={this.yesDeleteAlert.bind(this)} noClick={this.closeDeleteAlert.bind(this)} />
                <SignatureBox open={this.state.signatureClick} close={this.CloseSignature} addSignature={this.AddSignature} sendSignature={this.SubmitPersonalRegistration}/>
            </div>
        );
    }
}